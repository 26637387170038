// API bindings: organization (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { NewOrganizationResponse, NewOrganizationRequest, Organization, OrganizationUpdateRequest, OrganizationPictureResponse, InviteUserRequestBody, UserMemberItem, RoomList, InviteUserList, OrganizationList } from "./definitions";

export class ApiOrganization {
    /**
     * Method: POST
     * Path: /organization
     * Add a new organization
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostOrganization(body: NewOrganizationRequest): RequestParams<NewOrganizationResponse, PostOrganizationErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/organization`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "TOO_MANY", handler.badRequestTooMany)
                    .add(400, "INVALID_URL", handler.badRequestInvalidUrl)
                    .add(400, "INVALID_DESCRIPTION", handler.badRequestInvalidDescription)
                    .add(400, "INVALID_NAME", handler.badRequestInvalidName)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /organization/{id}
     * Get organization
     * @param id Organization ID
     * @returns The request parameters
     */
    public static GetOrganizationId(id: string): RequestParams<Organization, GetOrganizationIdErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /organization/{id}
     * Update an organization
     * @param id Organization ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostOrganizationId(id: string, body: OrganizationUpdateRequest): RequestParams<void, PostOrganizationIdErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_URL", handler.badRequestInvalidUrl)
                    .add(400, "INVALID_DESCRIPTION", handler.badRequestInvalidDescription)
                    .add(400, "INVALID_NAME", handler.badRequestInvalidName)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /organization/{id}
     * Delete an organization
     * @param id Organization ID
     * @returns The request parameters
     */
    public static DeleteOrganizationId(id: string): RequestParams<void, DeleteOrganizationIdErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /organization/{id}/picture
     * Uploads a new organization image
     * @param id Organization ID
     * @param formParams FromData parameters
     * @returns The request parameters
     */
    public static PostOrganizationIdPicture(id: string, formParams: PostOrganizationIdPictureFormParameters): RequestParams<OrganizationPictureResponse, PostOrganizationIdPictureErrorHandler> {
        const formData = new FormData();

        formParams.file !== undefined && formData.append("file", formParams.file);

        return {
            method: "POST",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/picture`),
            form: formData,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_IMAGE", handler.badRequestInvalidImage)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /organization/{id}/picture
     * Delete an organization picture
     * @param id Organization ID
     * @returns The request parameters
     */
    public static DeleteOrganizationIdPicture(id: string): RequestParams<void, DeleteOrganizationIdPictureErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/picture`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /organization/{id}/users/{uid}/invite
     * Invite a user to the organisation
     * @param id Organization ID
     * @param uid User ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostOrganizationIdUsersUidInvite(id: string, uid: string, body: InviteUserRequestBody): RequestParams<void, PostOrganizationIdUsersUidInviteErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/users/${encodeURIComponent(uid)}/invite`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "USER_NOT_FOUND", handler.notFoundUserNotFound)
                    .add(404, "ORGANIZATION_NOT_FOUND", handler.notFoundOrganizationNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_ROLE", handler.badRequestInvalidRole)
                    .add(400, "ALREADY_MEMBER", handler.badRequestAlreadyMember)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /organization/{id}/users/{uid}/invite
     * Removes invite
     * @param id Organization ID
     * @param uid User ID
     * @returns The request parameters
     */
    public static DeleteOrganizationIdUsersUidInvite(id: string, uid: string): RequestParams<void, DeleteOrganizationIdUsersUidInviteErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/users/${encodeURIComponent(uid)}/invite`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "USER_NOT_FOUND", handler.notFoundUserNotFound)
                    .add(404, "ORGANIZATION_NOT_FOUND", handler.notFoundOrganizationNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /organization/{id}/users
     * Get the list of members of the organization
     * @param id Organization ID
     * @returns The request parameters
     */
    public static GetOrganizationIdUsers(id: string): RequestParams<UserMemberItem[], GetOrganizationIdUsersErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/users`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /organization/{id}/invites
     * Get the list of members of the organization
     * @param id Organization ID
     * @returns The request parameters
     */
    public static GetOrganizationIdInvites(id: string): RequestParams<UserMemberItem[], GetOrganizationIdInvitesErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/invites`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /organization/{id}/rooms
     * Gets list of rooms owned by the organization
     * @param id Organization ID
     * @param queryParams Query parameters
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static GetOrganizationIdRooms(id: string, queryParams: GetOrganizationIdRoomsQueryParameters, headerParams: GetOrganizationIdRoomsHeaderParameters): RequestParams<RoomList, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/rooms` + generateURIQuery(queryParams)),
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /organization/{id}/users/{uid}
     * Changes the role of a member (admin)
     * @param id Organization ID
     * @param uid User ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostOrganizationIdUsersUid(id: string, uid: string, body: InviteUserRequestBody): RequestParams<void, PostOrganizationIdUsersUidErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/users/${encodeURIComponent(uid)}`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "USER_NOT_FOUND", handler.notFoundUserNotFound)
                    .add(404, "ORGANIZATION_NOT_FOUND", handler.notFoundOrganizationNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_ROLE", handler.badRequestInvalidRole)
                    .add(400, "USER_IS_OWNER", handler.badRequestUserIsOwner)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /organization/{id}/users/{uid}
     * Removes user from the organization
     * @param id Organization ID
     * @param uid User ID
     * @returns The request parameters
     */
    public static DeleteOrganizationIdUsersUid(id: string, uid: string): RequestParams<void, DeleteOrganizationIdUsersUidErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/users/${encodeURIComponent(uid)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "USER_NOT_FOUND", handler.notFoundUserNotFound)
                    .add(404, "ORGANIZATION_NOT_FOUND", handler.notFoundOrganizationNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /organization/{id}/invite/find
     * Find users to invite
     * @param id Organization ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetOrganizationIdInviteFind(id: string, queryParams: GetOrganizationIdInviteFindQueryParameters): RequestParams<InviteUserList, GetOrganizationIdInviteFindErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/organization/${encodeURIComponent(id)}/invite/find` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /organization/list
     * List organizations
     * @returns The request parameters
     */
    public static GetOrganizationList(): RequestParams<OrganizationList[], GetOrganizationListErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/organization/list`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for PostOrganization
 */
export type PostOrganizationErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_NAME
     */
    badRequestInvalidName: () => void;

    /**
     * Handler for status = 400 and code = INVALID_DESCRIPTION
     */
    badRequestInvalidDescription: () => void;

    /**
     * Handler for status = 400 and code = INVALID_URL
     */
    badRequestInvalidUrl: () => void;

    /**
     * Handler for status = 400 and code = TOO_MANY
     */
    badRequestTooMany: () => void;
};

/**
 * Error handler for GetOrganizationId
 */
export type GetOrganizationIdErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostOrganizationId
 */
export type PostOrganizationIdErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_NAME
     */
    badRequestInvalidName: () => void;

    /**
     * Handler for status = 400 and code = INVALID_DESCRIPTION
     */
    badRequestInvalidDescription: () => void;

    /**
     * Handler for status = 400 and code = INVALID_URL
     */
    badRequestInvalidUrl: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for DeleteOrganizationId
 */
export type DeleteOrganizationIdErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostOrganizationIdPicture
 */
export type PostOrganizationIdPictureErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_IMAGE
     */
    badRequestInvalidImage: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Form parameters for PostOrganizationIdPicture
 */
export interface PostOrganizationIdPictureFormParameters {
    /**
     * Image file to upload
     */
    file?: File;
}

/**
 * Error handler for DeleteOrganizationIdPicture
 */
export type DeleteOrganizationIdPictureErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for PostOrganizationIdUsersUidInvite
 */
export type PostOrganizationIdUsersUidInviteErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = ALREADY_MEMBER
     */
    badRequestAlreadyMember: () => void;

    /**
     * Handler for status = 400 and code = INVALID_ROLE
     */
    badRequestInvalidRole: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = ORGANIZATION_NOT_FOUND
     */
    notFoundOrganizationNotFound: () => void;

    /**
     * Handler for status = 404 and code = USER_NOT_FOUND
     */
    notFoundUserNotFound: () => void;
};

/**
 * Error handler for DeleteOrganizationIdUsersUidInvite
 */
export type DeleteOrganizationIdUsersUidInviteErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = ORGANIZATION_NOT_FOUND
     */
    notFoundOrganizationNotFound: () => void;

    /**
     * Handler for status = 404 and code = USER_NOT_FOUND
     */
    notFoundUserNotFound: () => void;
};

/**
 * Error handler for GetOrganizationIdUsers
 */
export type GetOrganizationIdUsersErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetOrganizationIdInvites
 */
export type GetOrganizationIdInvitesErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetOrganizationIdRooms
 */
export interface GetOrganizationIdRoomsQueryParameters {
    /**
     * Search query
     */
    q?: string;
}

/**
 * Header parameters for GetOrganizationIdRooms
 */
export interface GetOrganizationIdRoomsHeaderParameters {
    /**
     * Version ID (when joining with code)
     */
    "x-room-version-id"?: string;

    /**
     * Version Code (when joining with code)
     */
    "x-room-version-code"?: string;
}

/**
 * Error handler for PostOrganizationIdUsersUid
 */
export type PostOrganizationIdUsersUidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = USER_IS_OWNER
     */
    badRequestUserIsOwner: () => void;

    /**
     * Handler for status = 400 and code = INVALID_ROLE
     */
    badRequestInvalidRole: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = ORGANIZATION_NOT_FOUND
     */
    notFoundOrganizationNotFound: () => void;

    /**
     * Handler for status = 404 and code = USER_NOT_FOUND
     */
    notFoundUserNotFound: () => void;
};

/**
 * Error handler for DeleteOrganizationIdUsersUid
 */
export type DeleteOrganizationIdUsersUidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = ORGANIZATION_NOT_FOUND
     */
    notFoundOrganizationNotFound: () => void;

    /**
     * Handler for status = 404 and code = USER_NOT_FOUND
     */
    notFoundUserNotFound: () => void;
};

/**
 * Error handler for GetOrganizationIdInviteFind
 */
export type GetOrganizationIdInviteFindErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetOrganizationIdInviteFind
 */
export interface GetOrganizationIdInviteFindQueryParameters {
    /**
     * Search for registered users
     */
    q?: string;
}

/**
 * Error handler for GetOrganizationList
 */
export type GetOrganizationListErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

