/**
 * Websocket controller
 * 
 * To send messages use WebSocketController.send(msg)
 * To listen for events use WebSocketController.addEventListener(event, func)
 *  - Event 'connect' when the socket is connected
 *  - Event 'disconnect' when the socket disconnects
 *  - Rest of event messages, check protocol: 
 */

import { AppEvents } from "./app-events";
import { AuthController } from "./auth";

export function getSocketUrl(): string {
    if (import.meta.env.DEV) {
        return (location.protocol === "https:" ? "wss://" : "ws://") + "localhost/websocket";
    } else {
        return (location.protocol === "https:" ? "wss://" : "ws://") + location.host + "/websocket";
    }
}

export class WebSocketController {
    public static ws = null;
    public static connected = false;

    public static MAX_CONCURRENT_MESSAGES = 19;

    public static aliveTimeout = null;

    public static sentCounter = 0;
    public static queue = [];

    public static room = "";

    public static events = {};

    public static init() {
        AppEvents.AddEventListener("auth-status-changed", this.reconnect.bind(this));
        //this.connect();
    }

    /* Connection */
    public static connect() {
        if (this.ws) return; // Already connected

        this.ws = new WebSocket(getSocketUrl());

        if (this.aliveTimeout) {
            clearInterval(this.aliveTimeout);
            this.aliveTimeout = null;
        }

        this.aliveTimeout = setInterval(this.alive.bind(this), 10000);

        this.ws.onopen = function () {
            WebSocketController.connected = true;

            WebSocketController.sentCounter = 0;
            WebSocketController.queue = [];

            WebSocketController.authenticate();

            AppEvents.Emit("connect");
        }.bind(this);

        this.ws.onclose = function () {
            WebSocketController.ws = null;
            WebSocketController.connected = false;

            AppEvents.Emit("disconnect");


            WebSocketController.connect();
        }.bind(this);

        this.ws.onerror = function (err) {
            console.error(err);
        }.bind(this);

        this.ws.addEventListener("message", function (msg) {
            const message = JSON.parse("" + msg.data);
            //console.log("[WS] >> " + msg.data);
            WebSocketController.onMessage(message);
        }.bind(this));
    }

    public static reconnect() {
        if (this.ws) {
            this.ws.close();
        } else {
            this.connect();
        }
    }

    /* Send */

    public static send(msg) {
        if (this.ws && this.connected) {
            if (this.sentCounter >= this.MAX_CONCURRENT_MESSAGES) {
                this.queue.push(msg);
                return;
            }

            this.ws.send(JSON.stringify(msg));
            this.sentCounter++;

            if (this.sentCounter >= this.MAX_CONCURRENT_MESSAGES) {
                this.ws.send(JSON.stringify({ type: "promise" }));
            }
        }
    }

    // Método en WebSocketController para enviar datos de audio
    public static sendAudioData(blob: Blob, userId: string) {
        const reader = new FileReader();
        reader.onload = () => {
            const buffer = reader.result;
            this.ws.send(JSON.stringify({ userId, audioData: buffer }));
        };
        reader.readAsArrayBuffer(blob);
    }

    public static sendBlob(blob: Blob) {
        this.ws.send(blob, true);
    }

    /* Queue */

    public static releaseQueue() {
        const prevQueue = this.queue;
        this.queue = [];
        prevQueue.forEach(this.send.bind(this));
    }

    /* Keep alive */

    public static alive() {
        if (this.ws) {
            this.ws.send("a");
        }
    }

    /* Authentication */

    public static authenticate() {
        if (AuthController.isAuthenticated()) {
            this.send({
                type: "auth",
                session: AuthController.Session,
            });
        } 
        else if (AuthController.isAnon()) {
            this.send({
                type: "auth",
                name_token: AuthController.anonToken,
            });
        }
    }

    /* Join Rooms */

    public static joinCurrentRoom () {
        this.send({
            type: "room",
            room: this.room,
            //TODO:
            version_id: window.ROOM_VERSION,
            version_code: window.ROOM_VERSION_CODE,
        });
    }

    public static joinRoom (roomId: string) {
        this.room = roomId;
        this.joinCurrentRoom();
    }

    public static leaveRoom () {
        this.room = "";
        this.joinCurrentRoom();
    }

    public static registerUserActivity(room, active) {
        this.send({
            type: "activity",
            active: active,
        });
    }

    /* On message */

    public static onMessage(message) {
        switch (message.event) {
            case "hello":
                break;
            case "resolved":
                this.sentCounter = 0;
                this.releaseQueue();
                break;
            case "error":
                break;
            case "room":
                this.room = message.room;
                break;
            case "room-leave":
                this.room = "";
                break;
        }

        AppEvents.Emit(message.event, message);
    }
}