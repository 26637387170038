<template>
    <div class="notification" :class="{ hidden: !shown}">
        <template v-if="notification">
            <div v-if="notification.data.type === 'invitation_organization'" class="notification-box">
                {{ $t("You have received an invitation to join") }}: {{ notification.data.organizationName }}
                <router-link
                    class="d-flex justify-content-end external-link me-2"
                    :to="{ name: 'organization', params: { id: notification.data.organizationId } }">
                    {{ $t("Show invitation") }}
                </router-link>
            </div>
            <div v-else class="notification-box">
                <p>{{ $t("Unknown notification.") }}</p>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "NotificationModal",
    props: {
        message: String,
    },
    setup() {
        return {
            timeout: null,
        };
    },
    data: function () {
        return {
            shown: false,
            notification: null,
        };
    },
    methods: {
        show: function (notification: any) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }

            this.shown = true;
            this.notification = notification;

            this.timeout = setTimeout(() => {
                this.shown = false;
            }, 6000);
        },

        hide: function () {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.shown = false;
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("show-notification", this.show.bind(this));
    },
    beforeUnmount: function () {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    },
});
</script>
