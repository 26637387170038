/**
 * Processes the icon value to determine if it is an HTML element or a class string
 * @param icon The icon value to process
 * @returns The class string of the icon
 */
export function getIconClass(icon: string): string {
    let iconClass = "fa-solid fa-check";

    // Check if the value is an HTML element or a class string
    if (icon.includes('<i') && icon.includes('class=')) {
        // Extract the class of the icon from the HTML element
        const classMatch = icon.match(/class="([^"]+)"/);
        if (classMatch) {
            iconClass = classMatch[1];
        }
    } else {
        // Assume that the value is a string of class
        iconClass = icon;
    }

    // Verify if the iconClass starts with 'fa-'
    if (!iconClass.startsWith('fa-')) {
        return "fa-solid fa-notdef";
    } else {
        return iconClass;
    }

}