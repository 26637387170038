<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus">
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">{{ title }} </div>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ message }}</p>
                    <div v-if="danger" class="form-check form-switch">
                        <input class="form-check-input cursor-pointer" type="checkbox" role="switch"
                            v-model="confirmed" />
                        <label>{{ $t("I understand that this is a destructive action with non-reversible effects.")
                            }}</label>
                    </div>
                    <div v-if="showAgain" class="form-check form-switch d-flex">
                        <CustomCheck class="me-2" @click="showModal = !showModal"> </CustomCheck>
                        <label>{{ $t("Do not show this message again.")
                            }}</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="confirm" v-if="!danger && !simpleDelete">
                        <i :class="processedIcon"></i> {{ $t("Confirm") }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="confirm" :disabled="!confirmed" v-if="danger">
                        <i class="fa-solid fa-trash-can"></i> {{ $t("Delete") }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="confirm" v-if="simpleDelete">
                        <i class="fa-solid fa-trash-can"></i> {{ $t("Delete") }}
                    </button>
                    <button type="button" class="btn btn-cancel" @click="close">
                        {{ $t("Cancel") }}
                    </button>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";
import { getIconClass } from "@/utils/icon-checker";
import CustomCheck from "@/components/utils/CustomizableCheck.vue";
import { saveIntoLocalStorage } from "@/utils/local-storage";

export default defineComponent({
    name: "ConfirmationModal",
    emits: ["update:display", "confirm"],
    components: {
        CustomCheck,
    },
    props: {
        display: Boolean,
        title: String,
        message: String,
        danger: Boolean,
        simpleDelete: Boolean,
        showAgain: Boolean,
        icon: {
            type: String,
            default: "fa-solid fa-check",
        },
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            confirmed: false,
            showModal: true,
            processedIcon: getIconClass(this.icon),
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        confirm: function () {
            if (this.showAgain && !this.showModal) {
                saveIntoLocalStorage("showConfirmModal", false);
            }
            this.$emit("confirm");
            this.close();
        },
    },
    mounted: function () {
        if (this.display) {
            this.$autoFocus();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>

<style></style>
