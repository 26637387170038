<template>
    <div class="main-layout" :class="{
        'sidebar-hidden': !sidebarExpanded,
        'sidebar-non-sticky': !sidebarSticky,
        'dark-theme': isDarkTheme,
        'light-theme': !isDarkTheme,
    }">
        <a href="#" @click="skipMainContent" class="skip-main-content">
            <span>{{ $t("Skip to main content") }}</span>
        </a>

        <img v-if="backgroundImage" :src="backgroundImage" class="room-background-image">
        <div v-if="backgroundImage" class="room-background-image-overlay"></div>

        <TopBar v-if="!isSingleView" @toggle-menu="toggleMenu" v-model:expanded="sidebarExpanded"
            @openModal="openModal"></TopBar>
        <MenuSideBar v-if="!isSingleView" @toggle-menu="toggleMenu" v-model:expanded="sidebarExpanded"
            @openModal="openModal"></MenuSideBar>
        <div v-if="sidebarExpanded" class="side-bar-overlay" @click="closeSideBar"></div>
        <AuthLoadingOverlay :display="loadingAuth"></AuthLoadingOverlay>
        <router-view v-if="!loadingAuth"></router-view>
        <LoadingOverlay v-if="loadingRoute"></LoadingOverlay>

        <ChangeLanguageModal v-if="displayModalLanguage" v-model:display="displayModalLanguage"></ChangeLanguageModal>

        <ProfileModal v-if="displayProfileModal" v-model:display="displayProfileModal" :uid="profileModalUser">
        </ProfileModal>

        <LogoutModal v-if="displayModalLogout" v-model:display="displayModalLogout"></LogoutModal>

        <MessageModal v-if="displayModalMessage" v-model:display="displayModalMessage" :title="messageModalTitle"
            :message="messageModalMessage"></MessageModal>

        <MessageIconModal v-if="displayIconModalMessage" v-model:display="displayIconModalMessage"
            :title="messageIconModalTitle" :message="messageIconModalMessage" :icon="messageIconModalIcon">
        </MessageIconModal>

        <ConfirmationModal
            v-if="displayConfirmationModal"
            v-model:display="displayConfirmationModal"
            :title="confirmationTitle"
            :message="confirmationMessage"
            :danger="confirmationDanger"
            :simple-delete="confirmationSimpleDelete"
            :show-again="confirmationShowAgain"
            :icon="confirmationIcon"
            @confirm="onConfirm"
        >
        </ConfirmationModal>

        <ImageSelectorModal v-if="displayImageSelectorModal" v-model:display="displayImageSelectorModal"
            :file="selectorImageFile" @confirm="onConfirmImageSelectorModal">
        </ImageSelectorModal>

        <RequestCallCodeModal v-if="displayRequestCallCodeModal" v-model:display="displayRequestCallCodeModal"
            :readOnly="callCodeReadOnly" :oldCode="callCode" @confirm="onRequestCallCode">
        </RequestCallCodeModal>

        <UpdateStreamingVideoModal v-if="displayUpdateStreamingVideoModal"
            v-model:display="displayUpdateStreamingVideoModal" v-model:initName="updateStreamingVideoName"
            @confirm="onConfirmUpdateStreamingVideo">
        </UpdateStreamingVideoModal>

        <EventsDayModal v-if="displayEventsModal" v-model:display="displayEventsModal" :events="events">
        </EventsDayModal>


        <CookiesModal v-if="displayModalCookies" v-model:display="displayModalCookies"></CookiesModal>

        <CreateRoomModal v-if="displayCreateRoom" v-model:display="displayCreateRoom" v-model:icode="joinRoomCode" />
        <CreateOrganizationModal v-if="displayCreateOrganization" v-model:display="displayCreateOrganization" />

        <StreamingModalMain ref="streamingModalMain"></StreamingModalMain>

        <SnackBar></SnackBar>
        <NotificationModal></NotificationModal>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TopBar from "@/components/layout/TopBar.vue";
import MenuSideBar from "@/components/layout/MenuSideBar.vue";
import LoadingOverlay from "@/components/layout/LoadingOverlay.vue";
import AuthLoadingOverlay from "@/components/layout/AuthLoadingOverlay.vue";
import SnackBar from "@/components/layout/SnackBar.vue";

import LogoutModal from "@/components/modals/auth/LogoutModal.vue";

import ChangeLanguageModal from "@/components/modals/ChangeLanguageModal.vue";

import ProfileModal from "@/components/modals/ProfileModal.vue";

import MessageModal from "@/components/modals/MessageModal.vue";
import MessageIconModal from "@/components/modals/MessageIconModal.vue";

import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import RequestCallCodeModal from "@/components/modals/RequestCallCodeModal.vue";
import UpdateStreamingVideoModal from "@/components/modals/UpdateStreamingVideoModal.vue";
import ImageSelectorModal from "@/components/modals/ImageSelectorModal.vue";

import CookiesModal from "@/components/modals/CookiesModal.vue";

import CreateRoomModal from "@/components/modals/CreateRoomModal.vue";
import CreateOrganizationModal from "@/components/modals/CreateOrganizationModal.vue";

import StreamingModalMain from "@/components/modals/streaming-modal/StreamingModalMain.vue";

import { AuthController } from "@/control/auth";
import { AppPreferences } from "@/control/app-preferences";
import { RoomController } from '@/control/room';

import { fetchFromLocalStorageCache, saveIntoLocalStorage } from "@/utils/local-storage";
import EventsDayModal from "@/components/modals/EventsDayModal.vue";
import NotificationModal from "@/components/modals/NotificationModal.vue";

export default defineComponent({
    components: {
        TopBar,
        MenuSideBar,
        LoadingOverlay,
        AuthLoadingOverlay,
        SnackBar,
        LogoutModal,
        MessageModal,
        MessageIconModal,
        ChangeLanguageModal,
        ConfirmationModal,
        RequestCallCodeModal,
        ProfileModal,
        CookiesModal,
        CreateRoomModal,
        StreamingModalMain,
        UpdateStreamingVideoModal,
        ImageSelectorModal,
        EventsDayModal,
        CreateOrganizationModal,
        NotificationModal,
    },
    name: "MainLayout",
    setup: function () {
        return {
            confirmationCallback: null,
            imageSelectorCallback: null,
            updateStreamingVideoCallback: null,
            requestCallCodeCallback: null,
            resizeObserver: null as ResizeObserver,
        };
    },
    data: function () {
        return {
            loadingRoute: false,

            sidebarExpanded: fetchFromLocalStorageCache("sidebar-expanded", true),
            sidebarSticky: true,

            backgroundImage: "",

            isDarkTheme: false,
            loadingAuth: AuthController.Loading && !AuthController.FirstTimeLoaded,

            displayModalLogout: false,

            displayModalLanguage: false,

            displayModalMessage: false,
            messageModalTitle: "",
            messageModalMessage: "",

            displayIconModalMessage: false,
            messageIconModalTitle: "",
            messageIconModalMessage: "",
            messageIconModalIcon: "",

            displayConfirmationModal: false,

            selectorImageFile: null,
            displayImageSelectorModal: false,

            displayRequestCallCodeModal: false,
            callCode: "",
            callCodeReadOnly: false,

            updateStreamingVideoName: "",
            displayUpdateStreamingVideoModal: false,

            confirmationTitle: "",
            confirmationMessage: "",
            confirmationDanger: false,
            confirmationSimpleDelete: false,
            confirmationShowAgain: false,
            confirmationIcon: "",

            displayProfileModal: false,
            profileModalUser: "",

            displayModalCookies: false,

            displayCreateRoom: false,
            joinRoomCode: "",

            isForcedRoom: false,
            roomForcedId: "",

            roomId: "",

            isSingleView: true,

            displayEventsModal: false,
            events: "",

            displayCreateOrganization: false,
        };
    },
    methods: {
        onAuthStatusUpdate: function () {
            this.displayModalLogout = false;

            this.displayModalLanguage = false;
        },

        closeSideBar: function () {
            this.sidebarExpanded = false;
            saveIntoLocalStorage("sidebar-expanded", this.sidebarExpanded);
        },

        onAuthLoadingChanged: function () {
            this.loadingAuth = AuthController.Loading && !AuthController.FirstTimeLoaded;
        },

        onThemeChanged: function () {
            this.isDarkTheme = AppPreferences.Theme === "dark";
        },

        toggleMenu: function () {
            this.sidebarExpanded = !this.sidebarExpanded;
            saveIntoLocalStorage("sidebar-expanded", this.sidebarExpanded);
        },

        skipMainContent: function (event) {
            if (event) {
                event.preventDefault();
            }
            const content: any = document.querySelector(".page-content");
            if (content) {
                content.focus();
            }
        },

        showMessage: function (title: string, msg: string) {
            this.messageModalTitle = title;
            this.messageModalMessage = msg;
            this.displayModalMessage = true;
        },

        showMessageIcon: function (title: string, msg: string, icon: string) {
            this.messageIconModalTitle = title;
            this.messageIconModalMessage = msg;
            this.messageIconModalIcon = icon;
            this.displayIconModalMessage = true;
        },

        showEventsModal: function (options: string) {
            this.events = options;
            this.displayEventsModal = true;
        },

        openModal: function (name: string) {
            switch (name) {
                case "change-language-modal":
                    this.displayModalLanguage = true;
                    break;
                case "logout":
                    this.displayModalLogout = true;
                    break;
                case "create-room":
                    this.displayCreateRoom = true;
                    this.joinRoomCode = "";
                    break;
                case "create-organization":
                    this.displayCreateOrganization = true;
                    break;
            }
        },

        onOpenStreamingModal: function (sid: string, room: string, title: string) {
            this.$refs.streamingModalMain.showStreaming(sid, room, title);
        },

        onOpenCreateRoomModal: function (roomId: string) {
            this.joinRoomCode = roomId;
            this.displayCreateRoom = true;
        },
        onOpenCreateOrganizationModal: function () {
            this.displayCreateOrganization = true;
        },

        onShowRoomAccessDenied: function () {
            //TODO:
            console.log("Access denied");
        },

        onShowAllRooms: function () {
            this.$router.push({ name: "showall" });
        },

        onKickUser: function () {
            this.$showMessageModal(this.$t("Kicked"), this.$t("You have been kicked from the room by a moderator."));
            this.$router.push({ name: "showall" });
        },

        onRouterLoading: function (l: boolean) {
            this.loadingRoute = l;
        },

        onRouteChanged: function () {
            const s = (this.$route.query.s || "") + "";
            if (s !== "single") {
                this.isSingleView = false;
            } else {
                this.isSingleView = true;
            }

            if (this.$route && this.$route.meta && typeof this.$route.meta === "object") {
                this.sidebarSticky = !!this.$route.meta.sidebarSticky;
            } else {
                this.sidebarExpanded = false;
                this.sidebarSticky = false;
            }

            const cookiePref = AppPreferences.CookiesPreference;

            if (!cookiePref && (!this.$route || !(this.$route.name in { terms: 1, privacy: 1, cookies: 1 }))) {
                this.displayModalCookies = true;
            } else {
                this.displayModalCookies = false;
            }
        },

        showConfirmationModal: function (options: { title: string; message: string; danger?: boolean; simpleDelete?: boolean, showAgain?: boolean, icon?:string; callback: () => void }) {
            this.displayConfirmationModal = true;
            this.confirmationTitle = options.title;
            this.confirmationMessage = options.message;
            this.confirmationDanger = !!options.danger;
            this.confirmationSimpleDelete = !!options.simpleDelete;
            this.confirmationShowAgain = !!options.simpleDelete;
            this.confirmationIcon = options.icon;
            this.confirmationCallback = options.callback;
        },

        showImageSelectorModal: function (options: { file: File; callback: (file: File) => void }) {
            this.imageSelectorCallback = options.callback;
            this.selectorImageFile = options.file;
            this.displayImageSelectorModal = true;
        },

        onConfirmImageSelectorModal: function (file: File) {
            if (this.imageSelectorCallback) {
                this.imageSelectorCallback(file);
            }
        },

        showRequestCallCodeModal: function (options: { readonly: boolean, code: string, callback: (code: string) => void }) {
            this.displayRequestCallCodeModal = true;
            this.callCodeReadOnly = options.readonly;
            this.callCode = options.code;
            this.requestCallCodeCallback = options.callback;
        },

        onConfirm: function () {
            if (this.confirmationCallback) {
                this.confirmationCallback();
            }
        },

        onRequestCallCode: function (code: string) {
            if (this.requestCallCodeCallback) {
                this.requestCallCodeCallback(code);
            }
        },

        showUpdateStreamingVideoModal: function (options: { title: string; callback: (title: string, resolve: any, reject: any) => void }) {
            this.displayUpdateStreamingVideoModal = true;
            this.updateStreamingVideoName = options.title;
            this.updateStreamingVideoCallback = options.callback;
        },

        onConfirmUpdateStreamingVideo: function (title: string, resolve: any, reject: any) {
            if (this.updateStreamingVideoCallback) {
                this.updateStreamingVideoCallback(title, resolve, reject);
            }
        },

        onShowProfile: function (uid: string) {
            this.profileModalUser = uid;
            this.displayProfileModal = true;
        },

        onPageResize: function () {
            this.sidebarExpanded = fetchFromLocalStorageCache("sidebar-expanded", true);
        },

        onRoomMetaUpdated: function (roomId, meta) {
            this.roomId = roomId;
            if (meta) {
                this.backgroundImage = meta.background;
            } else {
                this.backgroundImage = "";
            }
        },

        onNotification: function (notification: any) {
            if(this.$route.name === "organization"){
                switch (notification.data.type) {
                    case "invitation_organization":
                        this.$showSnackBar(this.$t("You have a pending invitation"), "right");
                        break;
                }
                return;
            }
            if(this.$route.name !== "activity") {
                this.$showNotification(notification);
            }
            
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("auth-status-changed", this.onAuthStatusUpdate.bind(this));

        this.$listenOnAppEvent("auth-status-loading", this.onAuthLoadingChanged.bind(this));

        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));
        this.onThemeChanged();

        this.$listenOnAppEvent("msg-modal", this.showMessage.bind(this));
        this.$listenOnAppEvent("msg-modal-icon", this.showMessageIcon.bind(this));
        this.$listenOnAppEvent("ask-confirmation", this.showConfirmationModal.bind(this));
        this.$listenOnAppEvent("show-events-day", this.showEventsModal.bind(this));

        this.$listenOnAppEvent("image-selector-modal", this.showImageSelectorModal.bind(this));

        this.$listenOnAppEvent("request-call-code", this.showRequestCallCodeModal.bind(this));

        this.$listenOnAppEvent("show-update-streaming-video", this.showUpdateStreamingVideoModal.bind(this));
        this.$listenOnAppEvent("show-profile", this.onShowProfile.bind(this));
        this.$listenOnAppEvent("show-streaming-modal", this.onOpenStreamingModal.bind(this));

        this.$listenOnAppEvent("router-load-state-change", this.onRouterLoading.bind(this));

        this.$options.roomMetaHandler = this.onRoomMetaUpdated.bind(this);
        RoomController.on("room-meta", this.$options.roomMetaHandler);
        RoomController.on("open-create-room-modal", this.onOpenCreateRoomModal.bind(this));
        RoomController.on("open-create-organization-modal", this.onOpenCreateOrganizationModal.bind(this));
        RoomController.on("room-access-denied", this.onShowRoomAccessDenied.bind(this));
        RoomController.on("show-all-rooms", this.onShowAllRooms.bind(this));
        RoomController.on("room-kick", this.onKickUser.bind(this));
        this.onRoomMetaUpdated(RoomController.room, RoomController.meta);

        this.$listenOnAppEvent("notification", this.onNotification.bind(this));

        this.onAuthStatusUpdate();

        this.onRouteChanged();

        if (window.ResizeObserver) {
            this.resizeObserver = new ResizeObserver(this.onPageResize.bind(this));
            this.resizeObserver.observe(this.$el);
        }
    },
    watch: {
        $route: function () {
            this.onRouteChanged();
        },
    },
    beforeUnmount: function () {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
});
</script>

<style></style>
