<template>
  <div @click="toggleCheck">
    <i class="fa-solid fa-square-check" v-if="isChecked"></i>
    <i class="fa-regular fa-square" v-else></i>
  </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";


export default defineComponent({
  name: "CustomCheck",

  data: function () {
    return {
      isChecked: false,
    };
  },
  methods: {
    toggleCheck() {
      this.isChecked = !this.isChecked;
    },
  },
});

</script>

<style scoped>
div {
  cursor: pointer;
}

i {
  color: var(--red-maya);
}
</style>