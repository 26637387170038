<template>
    <div class="rooms-list-container">
        <div v-for="room in rooms" :key="room.id" class="rooms-list-item" tabindex="0" @click="clickOnRoom(room.id, room)">
            <div class="room-list-image-container">
                <img v-if="room.picture" class="room-list-img" :src="room.picture" :alt="room.name">
                <div v-if="!room.picture" class="room-list-noimg"><div>{{getFirstChar(room.name)}}</div></div>
            </div>
            <div class="room-list-room-container">
                <div class="room-list-item-header">
                    <div class="room-list-name" v-html="renderText(room.name, query)"></div>
                    <div v-if="room.isPublic" class="room-list-privacy">({{ $t("Public") }})</div>
                    <div v-if="!room.isPublic" class="room-list-privacy">({{ $t("Private") }})</div>
                </div>
                <div class="room-list-item-body">
                    <div class="room-list-item-desc" v-html="renderText(room.description, query)"></div>
                    <div class="room-list-item-date">{{ $t("Creation date") }}: {{renderDate(room.created)}}</div>
                    <div class="room-list-item-owner" v-if="room.owner && room.owner.username">{{ $t("Owned by") }} <a class="room-owner" href="javascript:;" @click="clickOnOwner($event, room)">@{{room.owner.username}}</a></div>
                </div>
            </div>
        </div>
        <div v-if="!more && rooms.length === 0" class="rooms-list-empty pt-2">
            <div class="form-group">{{ $t("Could not find any room") }}</div>
            <div class="form-group" v-if="showcreate"><button type="button" class="btn btn-primary" @click="createRoom"><i class="fas fa-plus"></i> {{ $t("Create a room") }}</button></div>
        </div>
        <div class="rooms-list-loader pt-2" :class="{'loader-hidden': !more}">{{ $t("Loading more items") }}...</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { generateSearchHighlights } from "@/utils/text";
import { renderDate } from "@/utils/time-utils";
import { elementInView } from "@/utils/in-view";

export default defineComponent({
    components: {},
    name: "RoomsList",
    props: ['rooms', 'more', 'loading', 'query', 'showcreate'],
    data: function () {
        return {};
    },
    methods: {
        tick: function () {
            if (this.loading || !this.more) {
                return;
            }
            const el = this.$el.querySelector(".rooms-list-loader");
            if (el && elementInView(el)) {
                this.$emit("load-more");
            }
        },

        getFirstChar: function (name) {
            return name.substr(0, 2) || "??";
        },

        clickOnRoom: function (rid, data) {
            this.$emit("select", rid, data);
        },

        renderDate: function (date) {
            return renderDate(date, this.$t);
        },

        renderText: function (text, query) {
            return generateSearchHighlights(text, query || "");
        },

        createRoom: function () {
            //App.showRoomAddModal();
        },

        clickOnOwner: function (event, room) {
            event.stopPropagation();
            this.$showProfileModal(room.owner.id);
        }
    },
    mounted: function () {
        this.$options.timer = setInterval(this.tick.bind(this), 100);
    },
    beforeUnmount: function () {
        clearInterval(this.$options.timer);
    },
});
</script>

<style scoed>
@import "@/style/room-list.css";
</style>
