<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus">
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("Events") }}</h5>
                    <button type="button" class="btn-close" :title="$t('Close')" @click="close"></button>
                </div>

                <div class="modal-body">
                    <div class="overflow-auto" style="max-height: 40vh;">
                        <div v-for="event in eventsDay" :key="event.title" class="mb-3">
                            <h5 class="w-100 text-start">
                                <span class="title">{{ event.title }}</span> - {{ event.hour }}
                            </h5>
                            <p class="w-100 text-start">{{ event.message }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalDialogContainer>
</template>




<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";

export default defineComponent({
    name: "EventsDayModal",
    emits: ["update:display"],
    props: {
        display: Boolean,
        events: String,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
            title: "",
            hour: "",
            message: "",
            eventsDay: [],
        };
    },
    methods: {
        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        loadOptions: function () {
            const lines = this.events.split("\n");
            const filteredArray = lines.filter(item => item.trim() !== "");
            filteredArray.forEach(line => {
                const parts = line.split(" - ");
                for (let i = 0; i < parts.length; i++) {
                    if (i === 0) {
                        this.title = parts[i];
                    } else if (i === 1) {
                        this.hour = parts[i];
                    } else {
                        this.message = parts[i];
                    }
                }
                this.eventsDay.push({ title: this.title, hour: this.hour, message: this.message });
            });

        },
    },
    mounted: function () {
        if (this.display) {
            this.$autoFocus();
        }
        this.loadOptions();
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>

<style>
.title {
    color: var(--red-maya) !important;
}

.modal-body {
    border-bottom: 2px solid var(--grey-maya) !important;
}
</style>